import React from 'react';
import { Link } from 'gatsby';
import { Layout } from '../../../components/Layout';
import img1 from '../../../images/services/property-line-surveys-in-calgary-img1.jpg';
import img2 from '../../../images/services/property-line-surveys-in-calgary-img2.jpg';

const PropertyLineSurveys = () => (
  <Layout
    title="Property and Fence Line Surveys in Calgary and Red Deer | Accurate Boundary Services"
    description="Need a property line survey in Calgary? Axiom Geomatics offers fast and reliable services to help you define accurate property boundaries. Book a consultation today!"
    keywords="property line survey, fence line survey, property line stakeout, fence line stakeout, markup property line, find a property line"
  >
    <main>
      <h1>Property & Fence Line Surveys Calgary</h1>

      <div className="text-center my-4">
        <img src={img1} alt="" width={800} height={450} />
      </div>

      <p>
        Whether in an urban or rural setting, understanding the location of your property boundaries
        is essential. A property line survey accurately identifies where the borders of the property
        are located.
      </p>
      <p>
        Axiom Geomatics provides property line surveys where we stake out the property
        border/boundary; we mark directly on the ground the location of the property line with
        orange construction stakes.
      </p>
      <p>Common problems when the location of a property line is not known are:</p>

      <ul>
        <li>Failing to meet municipal set-back requirements</li>
        <li>
          Construction of a feature (e.g. retaining wall, fence, building) on a neighbours property
        </li>
        <li>Building within public right-of-ways</li>
        <li>Lawsuits and/or destruction of the offending feature</li>
      </ul>

      <h2>Property Line Survey Cost in Calgary</h2>

      <p>
        A significant amount of detailed work is involved in accurately determining where property
        lines are located. The size of the lot, natural features, shape, and availability of survey
        evidence directly impact the time it takes to complete the project. A typical property in
        the City of Calgary takes at least 3 hours to complete. Large rural properties may require a
        couple of days. Please contact us if you are looking for a fence line stakeout.
      </p>
      <p>
        As per legislation, only a certified{' '}
        <strong>Alberta Land Surveyor is legally able to identify property boundaries.</strong> Make
        sure your markings are legally sound before beginning construction.
      </p>

      <div className="text-center my-4">
        <img src={img2} alt="" width={800} height={500} />
      </div>

      <h2>
        <abbr title="Frequently Asked Questions">Property & Fence Line Survey FAQs</abbr>
      </h2>

      <ul>
        <li>
          <Link to="#own-to-the-road">Don't I own up-to-the-road on my property?</Link>
        </li>
        <li>
          <Link to="#fence-style">What style can my fence be?</Link>
        </li>
        <li>
          <Link to="#fence-height">How tall can my fence be?</Link>
        </li>
        <li>
          <Link to="#fence-on-line">Does a fence need to be built on the property line?</Link>
        </li>
        <li>
          <Link to="#corner-lot">
            I own a corner lot, why can't I build to the edge of my property?
          </Link>
        </li>
      </ul>

      <h3 id="own-to-the-road">Don't I own up-to-the-road on my property?</h3>
      <p>
        Probably not. The property line can be anywhere from the sidewalk to many meters onto what
        you perceive as your front yard. There may also be utility right-of-ways on top of that. In
        most municipalities, you are responsible for the care and maintenance of the property in
        front of your home (e.g. snow shovelling, lawn care, etc…).
      </p>

      <h3 id="fence-style">What style can my fence be?</h3>
      <p>
        Typically, you can build a fence in any style you like, unless specific agreements or
        restrictions (restrictive covenants) are in place for your neighbourhood. These agreements
        are on the title and, commonly, they limit you to a 4-foot high, black, chain-link fence.
        There may also be restrictions on the height of the fence.
      </p>

      <h3 id="fence-height">How tall can my fence be?</h3>
      <p>
        Fence height is typically regulated by the municipal bylaws, and may be further restricted
        by neighbourhood-specific architectural controls. It is the responsibility of the property
        owner to adhere to all local rules and regulations. There are usually 2 heights allowed, one
        for the side and back of the house, and a separate, lower one for the front of the house.
      </p>

      <h3 id="fence-on-line">Does a fence need to be built on the property line?</h3>
      <p>
        No, you can build a fence inside of the property line, or directly on the line if your
        neighbour agrees to the construction. You may want to build inside the property line if your
        neighbour is unagreeable. It is always recommended to work with your neighbour where
        possible. Please note you are unable to build a fence along a utility right-of-way, and in
        this case, you MUST build on the property line.
      </p>

      <h3 id="corner-lot">I own a corner lot, why can't I build to the edge of my property?</h3>
      <p>
        Corner lots bordering roads have visibility restrictions to ensure vehicles can see clearly.
        Local regulations mandate a certain distance from the corner for any features (such as
        fences, buildings, etc…) and this distance varies by municipality. This concept is often
        referred to as the "visibility triangle”.
      </p>
    </main>
  </Layout>
);

export default PropertyLineSurveys;
